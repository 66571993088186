// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/api.join-form.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/api.join-form.tsx");
  import.meta.hot.lastModified = "1716452662854.4663";
}
// REMIX HMR END

import { json } from "@remix-run/node";
import { useFetcher } from "@remix-run/react";
import { submitJoinForm } from "~/api/form.server";
export async function action({
  request
}) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const formData = await request.formData();
  const email = formData.get("email");
  if (!email) return json({
    error: "Missing email"
  });
  if (!emailRegex.test(formData.get("email"))) return json({
    error: "Invalid email"
  });
  const formBody = {
    data: {
      email: formData.get("email")
    }
  };
  const response = await submitJoinForm(formBody);
  if (response.status !== 200) return json({
    error: response.statusText
  });
  const data = await response.json();
  return json({
    data: data
  });
}

// TODO: TYPE STRAPI FORM RESPONSE AND PASS TO USE FETCHER
// NOTE: PASS TYPES VIA GENERICS WHEN USING REACT COMPONENTS
const FormSubmit = ({
  placeholder,
  text
}) => {
  _s();
  const fetcher = useFetcher();
  if (fetcher.data && !fetcher.data.error) {
    return <div className="text-center mt-8">
        <h1 className="text-3xl font-bold mb-4 text-pink-500">Thank You!</h1>
        <p className="text-gray-500 text-lg">
          We appreciate your interest in our community. We'll be in touch soon!
        </p>
      </div>;
  }
  return <div className="flex flex-row items-center self-center justify-center flex-shrink-0 shadow-md lg:justify-end">
      <div className="flex flex-col">
        <div className="flex flex-row">
          <fetcher.Form method="post" action="/api/join-form">
            <input name={"email"} type="text" placeholder={placeholder} className={"w-3/5 p-3 rounded-l-lg sm:w-2/3 text-gray-700"} />
            <button type="submit" className="w-2/5 p-3 font-semibold rounded-r-lg sm:w-1/3 bg-violet-400 text-gray-900">
              {text}
            </button>
            {fetcher.data?.error && <p className="text-red-500 bg-red-200 px-4 py-2 rounded-lg my-2">
                {fetcher.data.error}
              </p>}
          </fetcher.Form>
        </div>
      </div>
    </div>;
};
_s(FormSubmit, "2WHaGQTcUOgkXDaibwUgjUp1MBY=", false, function () {
  return [useFetcher];
});
_c = FormSubmit;
export default FormSubmit;
var _c;
$RefreshReg$(_c, "FormSubmit");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;